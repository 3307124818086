var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('el-form',{attrs:{"inline":true}},[_c('el-form-item',{attrs:{"label":"状态"}},[_c('el-select',{attrs:{"placeholder":"状态","clearable":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_c('el-option',{attrs:{"label":"可用","value":1}}),_c('el-option',{attrs:{"label":"下架","value":0}})],1)],1),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.getList(1)}}},[_vm._v("搜索")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.addNew}},[_vm._v("新增")])],1)],1),_c('auto-table',{attrs:{"DataList":_vm.DataList,"option":_vm.Option,"total":_vm.Total},on:{"changePage":_vm.getList},scopedSlots:_vm._u([{key:"activity_time",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v("开始:"+_vm._s(row.start_time))]),_c('p',[_vm._v("结束:"+_vm._s(row.end_time))])])]}},{key:"answer",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"scroll-box"},[_vm._v(" "+_vm._s(row.answer)+" ")])]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('div',[(row.status === 0)?_c('el-tag',{attrs:{"type":"info","size":"small"}},[_vm._v("已下架")]):_vm._e(),(row.status === 1)?_c('el-tag',{attrs:{"type":"success","size":"small"}},[_vm._v("可用")]):_vm._e()],1)]}},{key:"handler",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.updateInfo(row)}}},[_vm._v("编辑")]),(row.status == 0)?_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":function($event){return _vm.updateStatus(row,1)}}},[_vm._v("使用")]):_vm._e(),(row.status == 1)?_c('el-button',{attrs:{"type":"warning","size":"small"},on:{"click":function($event){return _vm.updateStatus(row,0)}}},[_vm._v("下架")]):_vm._e()],1)]}}])}),_c('AddorEdit',{ref:"add",on:{"success":function($event){return _vm.getList(1)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }