<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="状态">
        <el-select v-model="status" placeholder="状态" clearable>
             <el-option label="可用" :value="1"></el-option>
          <el-option label="下架" :value="0"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addNew">新增</el-button>
      </el-form-item>
    </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #answer="{ row }">
        <div class="scroll-box">
          {{ row.answer }}
        </div>
      </template>
      
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 0">已下架</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 1"
            >可用</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="updateInfo(row)"
            >编辑</el-button
          >
            <el-button v-if="row.status == 0" type="success" size="small" @click="updateStatus(row,1)"
            >使用</el-button
          >
            <el-button v-if="row.status == 1"  type="warning" size="small" @click="updateStatus(row,0)"
            >下架</el-button
          >

        </div>
      </template>
    </auto-table>


    <AddorEdit ref="add" @success="getList(1)"></AddorEdit>
  </div>
</template>

<script>
import AddorEdit from './components/addOrEdit.vue';
export default {
  components:{
      AddorEdit
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "问题", value: "question" },
        { name: "回答", value: "answer",type: "custom" },
         { name: "置顶", value: "is_top" },
        { name: "状态", value: "status", type: "custom" },
        { name: "添加时间", value: "create_time" },
        { name: "操作", value: "handler", type: "custom" },
      ],
      Total: 0,
      status: 1,
    };
  },

  mounted() {
    this.getList(1);
  },

  methods: {

    addNew(){
      this.$refs.add.open()
    },

    updateInfo(row) {
       this.$refs.add.open(row)
    },
    updateStatus(row,status){
        this.$post('/chat/knowledgeBase/update',{id:row.id,status}).then(res=>{
          this.$message('成功')
          this.getList(1);
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/chat/knowledgeBase/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>

.scroll-box{
  overflow-y: auto;
  max-height: 100px;

}
</style>