<template>
  <!-- 代理商新增，修改 -->
  <div>
    <el-dialog title="编辑" :visible.sync="dialogVisible" width="50%" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="问题" required prop="question">
          <el-input v-model="form.question" placeholder="问题"></el-input>
        </el-form-item>
        <el-form-item label="回答" prop="answer" required>
          <el-input
            type="textarea"
            v-model="form.answer"
            placeholder="回答"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属平台" required>
          <el-radio v-model="form.platform" :label="1">海马运动</el-radio>
          <el-radio v-model="form.platform" :label="2">教练局</el-radio>
        </el-form-item>
         <el-form-item label="置顶" required>
          <el-radio v-model="form.is_top" :label="true">是</el-radio>
          <el-radio v-model="form.is_top" :label="false">否</el-radio>
        </el-form-item>
        <el-form-item label="分类" required>
          <el-cascader v-model="category_ids" @change="changeCategory" :options="categories_list" :props="props" :show-all-levels="false"></el-cascader>
        </el-form-item>

      <el-form-item label="关键字">
          <el-input
            v-model="form.keywords"
            placeholder="关键字"
          ></el-input>
          多个标签使用,号分隔
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          v-if="form.id"
          type="primary"
          @click="updateForm"
          :loading="loading"
          >更 新</el-button
        >
        <el-button v-else type="primary" :loading="loading" @click="addForm"
          >新 增</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { ObjectChangedValue } from "@/js/utils.js";
export default {
  data() {
    return {
      rules: {
        question: [
          {
            required: true,
            message: "请填写问题",
            trigger: ["change", "blur"],
          },
        ],
        answer: [
          {
            required: true,
            message: "请填写回答",
            trigger: ["change", "blur"],
          },
        ],
      },
      clear: false,
      form: {type:'person'},
      temp: {},
      dialogVisible: false,
      loading: false,
      categories_list: [],
      cateShow: false,
      changeCate: [],
      changeParam: "",
      category_ids:[],
      props:{
        value:'id',
        label:'name',
        children:'childList'
      }
    };
  },

  mounted() {
    this.getCategoriesList();
  },

  methods: {
    changeCategory(v){
        this.form.category_id = v[v.length -1]
    },
    openCate(v){
      this.changeCate = this.form[v] ? this.form[v].split(',') : []
        this.changeParam = v
        this.cateShow = true
    },
    confirmCate(){
        this.form[this.changeParam] = this.changeCate.join(',')
         this.cateShow = false
    },
    addForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          this.$axios({
            url: "/chat/knowledgeBase/insert",
            method: "post",
            data: this.form,
          })
            .then((res) => {
              this.$notify({
                message: "新增成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    updateForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const data = ObjectChangedValue(this.temp, this.form);
          if (Object.keys(data).length == 0) {
            this.$message("没有修改数据，无需更新");
            return;
          }
          this.loading = true;
          this.$axios({
            url: "/chat/knowledgeBase/update",
            method: "post",
            data: {
              id: this.form.id,
              ...data,
            },
          })
            .then((res) => {
              this.$notify({
                message: "更新成功",
                duration: 2000,
                type: "success",
              });
              this.dialogVisible = false;
              this.loading = false;
              this.$emit("success", 1);
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    open(info) {
      if (info && info.id) {
        this.temp = Object.assign({}, info);
        this.form = info;
        this.category_ids = [info.category_id]
      } else {
        this.form =  {};
        this.temp = {};
      }
      this.dialogVisible = true;
    },

    getProductImg(v) {
      this.form.pic = v;
    },
    getCategoriesList() {
       let url = "/chat/category/queryListPageChild";
      this.$axios
        .get(url, {})
        .then((res) => {
          this.categories_list = res.data.data;
        });
    },
  },
};
</script>

<style lang="less" scoped>
</style>